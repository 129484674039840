export const REDIRECT_URL = 'https://www.academylogin.com';

export const BASE_URL = process.env.GATSBY_BASE_URL;

export const API_URL = process.env.GATSBY_API_URL;

export const IS_CLIENT = typeof window === 'object';

export const URLS = {
  BECOME_AN_EDUCATOR: 'https://beofficial.typeform.com/to/PJju0xsv',
  MAKE_A_VIDEO_COURSE: 'https://beofficial.typeform.com/to/dACN2OKv',
  SUPPORT_PORTAL: 'https://help.livesupportnow.com/',
};

export const API = {
  SIGN_IN_INIT: '/mindhub/signin-init',
  SIGN_IN_FINAL: '/mindhub/signin-mha-final',
  AUTO_LOGIN: '/mindhub/autologin',
  FORGOT_USERNAME: '/mindhub/forgot-username',
  FORGOT_PASS: 'mindhub/forgot-password',
  RESET_PASS: 'mindhub/reset-password',
  GET_MAINTENANCE: '/shop/instance-types/in-maintenance',
};

export const ROUTES = {
  RESET_PASS: '​/resetpass',
};
